import type { ActivityGroup } from "./activity-group";
import type { Attendee } from "./attendee";
import type { Booking } from "./booking";
import type { Field } from "./field";
import type { FieldData } from "./field-data";
import type { FieldOption } from "./field-option";
import type { LineItem } from "./line-item";
import type { PaymentTransaction } from "./payment";
import type { User } from "./user";
import type { SubscriptionPlan } from "./subscription-plan";
import type { Venue } from "./venue";

export enum TicketType {
  All = "all",
  Single = "single",
  Subscription = "subscription"
}

export interface OverriddenSessionTimeDisplay {
  start: {
    hours: number;
    minutes: number;
  };
  end: {
    hours: number;
    minutes: number;
  };
}

export interface Ticket<SP = SubscriptionPlan> {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string;
  price: number;
  proRataPrice?: number;
  placeLimit?: number;
  type: TicketType;
  enabled: boolean;
  shouldProRata?: boolean;
  remainingSessionsCount?: number;
  haveSessionsPast?: boolean;
  restrictSessions?: boolean;
  sessionsCanBeUsedFor?: any[];
  subscriptionPlan?: SP;
  allowMidMonthBookings?: boolean;
  coverFeePerSession?: number;
  timeDisplay?: OverriddenSessionTimeDisplay;
  created?: string;
  updated?: string;
  save?: () => Promise<void>;
}
export interface ActivityDate {
  _id?: string;
  start: Date;
  end: Date;
}

export interface ActivityDateInstance {
  _id: string;
  enabled: boolean;
  placeLimit?: number;
  date: {
    start: Date;
    end: Date;
  };
}

export interface Activity<ActivityGroupType = ActivityGroup> {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  activityGroup?: ActivityGroupType;
  date: ActivityDate;
  enabled: boolean;
  placeLimit?: number;
  created?: string;
  updated?: string;
  client: string;
  equals?: (id: string) => boolean;
  deleteOne?: () => Promise<void>;
  save?: (options?: any) => Promise<void>;
}

export interface ActivityReduced {
  _id: string;
  title: string;
  dates: string;
}

export interface ActivityCurrent {
  _id: string;
  activityGroupId: string;
  startDate: Date;
  endDate: Date;
  title: string;
  placeLimit?: number;
  venue: string;
  session?: string;
}

export interface ActivityRepeat {
  frequency: ActivityRepeatFrequency;
  occurrences: number;
}

export enum ActivityRepeatFrequency {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly"
}

export interface ActivityDateGroup {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  activities: ActivityCurrent[];
  count: number;
}

export interface ActivityListingAggregatedResultItem extends Activity<string> {
  activityGroups: ActivityGroup;
  fields: Field[];
  venues: Venue[];
  fieldOptionValues: FieldOption[];
  allActivitiesInActivityGroup: Activity[];
  activityTickets: Ticket[];
  sales?: [
    {
      totalSales: number;
    }
  ];
}

export interface ActivityListingAggregatedResultItemTransformed
  extends ActivityListingAggregatedResultItem {
  activityGroupFieldData: FieldData[];
  session: string;
}

export interface ActivityListingByDateItem {
  _id: Date;
  activities: ActivityListingAggregatedResultItem[];
  count: number;
}

export interface AllSessionBookingInfoItem {
  _id: string;
  lineitemsTotal: number;
  bookingInfo: Booking;
  paymenttransactions: PaymentTransaction[];
  paymentTotal: number;
  balance: number;
}

export interface AllSessionActivityRegisterAggregatedResultItem {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  fullActivities: string[];
  allBookingIds: string[];
  addOns: LineItem<string, any>[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  allTicketIds: string[];
  allTicket_info: Ticket[];
  attendee_info: Attendee;
  attendeeFields: Field[];
  attendeeFieldOptionValues: FieldOption[];
  attendeeFieldOptionArrayValues: FieldOption[];
  user_info: User;
  userFields: Field[];
  userFieldOptionValues: FieldOption[];
  userFieldOptionArrayValues: FieldOption[];
  allBookingInfo: AllSessionBookingInfoItem[];
}

export interface AllSessionBookingInfoItem {
  _id: string;
  lineitemsTotal: number;
  bookingInfo: Booking;
  paymenttransactions: PaymentTransaction[];
  paymentTotal: number;
  balance: number;
}

export enum AttendanceLogType {
  CheckIn = "checkIn",
  CheckOut = "checkOut"
}

export interface AttendanceLogItem {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  activity: string;
  activityGroup: string;
  lineItem: string;
  type: AttendanceLogType;
  loggedByUser: string;
  cancelled?: boolean;
  created: Date;
  updated: Date;
  save?: () => Promise<void>;
}

export interface AttendanceLogCountsAggItem {
  _id: string;
  count: number;
}

export interface AttendanceLogCounts {
  checkInCount: number;
  checkOutCount: number;
  totalCount: number;
}

export enum AttendanceLogStatusFilter {
  Any = "any",
  CheckedIn = "checkedIn",
  NotCheckedIn = "notCheckedIn",
  CheckedOut = "checkedOut",
  NotCheckedOut = "notCheckedOut"
}

export interface AllSessionActivityRegisterAggregatedResultItem {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  fullActivities: string[];
  allBookingIds: string[];
  addOns: LineItem<string, any>[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  allTicketIds: string[];
  allTicket_info: Ticket[];
  attendee_info: Attendee;
  attendeeFields: Field[];
  attendeeFieldOptionValues: FieldOption[];
  attendeeFieldOptionArrayValues: FieldOption[];
  user_info: User;
  userFields: Field[];
  userFieldOptionValues: FieldOption[];
  userFieldOptionArrayValues: FieldOption[];
  allBookingInfo: AllSessionBookingInfoItem[];
  attendanceLogs: AttendanceLogItem[];
  allLineItemIds: string[];
}

export enum OverallAvailability {
  NoTicketsAvailable = "noTicketsAvailable",
  Available = "available",
  SoldOut = "soldOut",
  Limited = "limited"
}

export interface ActivitySalesDataAggItem {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  enabled: true;
  date: ActivityDate;
  client: string;
  activityGroup: string;
  placeLimit: number;
  overallAvailability: OverallAvailability;
}

export enum ActivityView {
  Activities = "activities",
  Sessions = "sessions",
  Calendar = "calendar"
}

export interface AddOn {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string;
  price: number;
  enabled: boolean;
  created?: string;
  updated?: string;
  restrictSessions?: boolean;
  sessionsCanBeUsedFor?: any[];
  toObject?: () => AddOn;
}

export interface AddOnWithActivity extends AddOn {
  activity?: Activity<string> | Partial<Activity<string>>;
  lineItemId?: string;
  parentId?: string;
}

export interface AddOnWithActivityAttendee extends AddOn {
  activity?: Activity<string> | Partial<Activity<string>>;
  lineItemId?: string;
  parentId?: string;
  attendee?: Attendee;
}

export interface SessionAvailabilityHash {
  [key: string]: OverallAvailability;
}

export interface GetActivitySessionsListingResponse {
  data: ActivityDateGroup[];
  nextPageOffset: number;
}

export interface GetActivitiesListingResponse {
  data: ActivityGroup[];
  nextPageOffset: number;
}

export interface SessionsBookedReportAggregatedResultItem {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  date: ActivityDate;
  placeLimit: number;
  booking_info: Booking;
  activityInfo: ActivityGroup;
  ticket_info: Ticket;
  user_info: User;
  attendee_info: Attendee;
  activityGroupFields: Field[];
  activityGroupFieldOptionArrayValues: FieldOption[];
  activityGroupFieldOptionValues: FieldOption[];
  activityGroupVenueValues: FieldOption[];
  userFields: Field[];
  userFieldOptionValues: FieldOption[];
  userFieldOptionArrayValues: FieldOption[];
  attendeeFields: Field[];
  attendeeFieldOptionValues: FieldOption[];
  attendeeFieldOptionArrayValues: FieldOption[];
  attendanceStatus: number;
}

export interface SalesForActivitiesAggregatedResultItem {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  activityInfo: ActivityGroup;
  date: ActivityDate;
  sales: {
    count: number;
    sum: number;
  };
  addOnSales: {
    count: number;
    sum: number;
  };
  subscriptionSales: {
    count: number;
  };
  placeLimit: number;
  activityGroupFields: Field[];
  activityGroupFieldOptionValues: FieldOption[];
  activityGroupFieldOptionArrayValues: FieldOption[];
  activityGroupVenueValues: FieldOption[];
}

export interface SalesForActivitiesSummary {
  sessionsCount: number;
  totalPlaces?: number;
  totalSalesCount: number;
  totalSalesValue: number;
}

export interface AttendanceReportSummary {
  totalPlaces?: number;
  totalSalesCount: number;
  checkInCount: number;
  checkOutCount: number;
}

export interface SalesForActivitiesByActivityGroupAggregatedResultItem {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  firstSessionStart: Date;
  lastSessionStart: Date;
  numberOfEnabledSessions: number;
  placeLimitMax: number;
  placeLimitMin: number;
  placesSoldTotal: number;
  ticketsSoldCountTotal: number;
  ticketsSoldRevenueTotal: number;
  addOnRevenueTotal: number;
  subscriptionsSoldTotal: number;
  placesSoldAverage: number;
  ticketsSoldCountAverage: number;
  ticketsSoldRevenueAverage: number;
  addOnRevenueAverage: number;
  subscriptionsSoldAverage: number;
  activityInfo: ActivityGroup;
  activityGroupFields: Field[];
  activityGroupFieldOptionValues: FieldOption[];
  activityGroupFieldOptionArrayValues: FieldOption[];
  activityGroupVenueValues: FieldOption[];
}

export interface MonthyRevenueReportDataItem {
  date: string;
  bookingsCount: number;
  bookingsRevenue: number;
  subscriptionsCount: number;
  subscriptionsRevenue: number;
  totalRevenue: number;
}

export interface AttendanceReportAggregatedResultItem {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  activityInfo: ActivityGroup;
  date: ActivityDate;
  placeLimit: number;
  sales: {
    count: number;
    sum: number;
  };
  checkInCount: number;
  checkOutCount: number;
  activityGroupFields: Field[];
  activityGroupFieldOptionValues: FieldOption[];
  activityGroupFieldOptionArrayValues: FieldOption[];
  activityGroupVenueValues: FieldOption[];
}
